import { mapFields } from 'vuex-map-fields';

export default {
  data() {
    return {
    };
  },
  methods: {
    existePrivilegios(funcionalidadId, accion) {
      const PRIVILEGIOS = this.companyPrivilegios;
      for (let i = 0; i < PRIVILEGIOS.length; i += 1) {
        if (PRIVILEGIOS[i].funcionalidad_id === parseInt(funcionalidadId, 10)
        && PRIVILEGIOS[i][accion] === 1) {
          return true;
        }
      }
      return false;
    },
  },
  computed: {
    ...mapFields('main', [
      'companyPrivilegios',
    ]),
  },
};
