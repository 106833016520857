<template>
  <div>
    <div class="row mb-3">
      <div class="col-sm-7">
        <div class="row mb-2">
          <div class="col-sm-2 text-center">
            <label class="col-form-label">Caja:</label>
          </div>
          <div class="col-sm-4">
            <vue-numeric class="form-control"
              v-model="caja"
              @change.native="actualizarDesdeCaja"
              separator=","
              v-bind:precision="2"
              :min="0"
            />
          </div>
          <div class="col-sm-2 pl-0">
            <button class="btn"
              :class="caja !== 0? 'btn-success':'btn-warning'"
              @click="setCaja">
            </button>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-2 text-center">
            <label class="col-form-label">Banco:</label>
          </div>
          <div class="col-sm-4">
            <vue-numeric class="form-control"
              v-model="banco"
              @change.native="actualizarDesdeBanco"
              separator=","
              v-bind:precision="2"
              :min="0"
            />
          </div>
          <div class="col-sm-2 pl-0">
            <button class="btn"
              :class="banco !== 0? 'btn-success':'btn-warning'"
              @click="setBanco">
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2 text-center">
            <label class="col-form-label">Tarjeta:</label>
          </div>
          <div class="col-sm-4">
            <vue-numeric class="form-control"
              v-model="tarjeta"
              @change.native="actualizarDesdeTarjeta"
              separator=","
              v-bind:precision="2"
              :min="0"
            />
          </div>
          <div class="col-sm-2 pl-0">
            <button class="btn"
              :class="tarjeta !== 0? 'btn-success':'btn-warning'"
              @click="setTarjeta">
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-5">
        <label class="col-form-label"
          v-show="getMedicoSelected!=null">
          Cuenta: {{showMedicoSelected}}
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4"></div>
      <div class="row col-sm-4">
        <label class="col-form-label col-sm-5 text-right">
          Importe:
        </label>
        <input type="text"
          class="form-control col-sm-6"
          :value="getTotal | numeral('0,0.00')"
          autocomplete="off"
          disabled>
      </div>
    </div>
  </div>
</template>

<script>
import VueNumeric from 'vue-numeric';
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'FormaPagoFacturaTercero',
  components: {
    VueNumeric,
  },
  data() {
    return {
    };
  },
  methods: {
    actualizarDesdeCaja() {
      if (this.getTotal - this.caja < 0) {
        this.caja = this.getTotal;
      }
      if (this.banco === 0) {
        this.tarjeta = this.getTotal - this.caja - this.banco;
      } else if (this.tarjeta === 0) {
        this.banco = this.getTotal - this.caja - this.tarjeta;
      }
    },
    actualizarDesdeBanco() {
      if (this.getTotal - this.banco < 0) {
        this.banco = this.getTotal;
      }
      if (this.caja === 0) {
        this.tarjeta = this.getTotal - this.caja - this.banco;
      } else if (this.tarjeta === 0) {
        this.caja = this.getTotal - this.banco - this.tarjeta;
      }
    },
    actualizarDesdeTarjeta() {
      if (this.getTotal - this.tarjeta < 0) {
        this.tarjeta = this.getTotal;
      }
      if (this.caja === 0) {
        this.banco = this.getTotal - this.caja - this.tarjeta;
      } else if (this.banco === 0) {
        this.caja = this.getTotal - this.banco - this.tarjeta;
      }
    },
    setTarjeta() {
      this.caja = 0;
      this.banco = 0;
      this.tarjeta = this.getTotal;
    },
    setBanco() {
      this.caja = 0;
      this.banco = this.getTotal;
      this.tarjeta = 0;
    },
    setCaja() {
      this.caja = this.getTotal;
      this.banco = 0;
      this.tarjeta = 0;
    },
  },
  computed: {
    ...mapFields('formaPagoFacturaTercero', [
      'caja',
      'banco',
      'tarjeta',
    ]),
    ...mapGetters('formFactura', [
      'getMedicoSelected',
      'showMedicoSelected',
    ]),
    ...mapGetters('detalleFactura', [
      'getTotal',
    ]),
  },
};
</script>

<style scoped>
</style>
