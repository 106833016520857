<template>
  <div>
    <b-modal id="modal-tratamiento"
    size="custom-lg-max"
    title="Orden de Trabajo"
    body-class="pt-0"
    hide-footer>
      <div class="row">
        <div class="col-sm-12 px-0">
          <b-table responsive class="table"
          :fields="fields"
          :items="listaTratamientos">
            <template #cell(total)="data">
              {{data.item.total | numeral('0,0.00')}}
            </template>
            <template #cell(acuenta)="data">
              {{data.item.acuenta | numeral('0,0.00')}}
            </template>
            <template #cell(saldo)="data">
              {{data.item.saldo | numeral('0,0.00')}}
            </template>
            <template #cell(estado)="data">
              <span :style="data.item.estado_id === 1 ? 'color:green': 'color:red'">
                {{ data.item.estado }}
              </span>
            </template>
            <template #cell(accion)="items">
              <div class="col-sm-12">
                <input :id="'checkModal'+items.index"
                  v-model="items.item.checkInput"
                  class="form-check-input"
                  :disabled="items.item.estado==='Abierto'"
                  type="checkbox"
                >
                <label
                  class="form-check-label" :for="'checkModal'+items.index"
                  style="user-select: none"
                >
                  Seleccionar
                </label>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-right">
          <button class="btn btn-outline-dark mr-2" @click="cancelarModal()">
            Cancelar
          </button>
          <button class="btn btn-success" @click="confirmarModal()">
            Guardar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalTratamiento',
  data() {
    return {
      fields: [
        {
          key: 'numero', label: 'N° Orden.', thClass: 'table-secondary', thStyle: { width: '18%' },
        },
        {
          key: 'sesion', label: 'Sesion.', thClass: 'table-secondary', thStyle: { width: '5%' },
        },
        {
          key: 'tratante', label: 'Tratante.', thClass: 'table-secondary', thStyle: { width: '24%' },
        },
        {
          key: 'total', label: 'Total.', thClass: 'table-secondary', thStyle: { width: '10%' },
        },
        {
          key: 'acuenta', label: 'Acuenta', thClass: 'table-secondary', thStyle: { width: '10%' },
        },
        {
          key: 'saldo', label: 'Saldo', thClass: 'table-secondary', thStyle: { width: '10%' },
        },
        {
          key: 'estado', label: 'Estado', thClass: 'table-secondary', thStyle: { width: '10%' },
        },
        {
          key: 'numero_vc', label: 'N°VC', thClass: 'table-secondary', thStyle: { width: '5%' },
        },
        {
          key: 'accion', label: '', thClass: 'table-secondary', thStyle: { width: '8%' },
        },
      ],
      listaTratamientos: [
        {
          numero: 'Cot 3/23',
          sesion: '5',
          tratante: 'Solicitante: Dr. Maria Ledezma',
          total: '1000',
          acuenta: 0,
          saldo: 0,
          estado: 'Pagado',
          estado_id: 1,
          numero_vc: '33/23',
        },
        {
          numero: 'OT 2/23',
          sesion: '1/5',
          tratante: 'Dr. Jose Leman',
          total: '200',
          acuenta: 200,
          saldo: 0,
          estado: 'Pendiente',
          estado_id: 2,
          numero_vc: '',
        },
        {
          numero: 'OT 2/23',
          sesion: '2/5',
          tratante: '',
          total: 200,
          acuenta: 100,
          saldo: 100,
          estado: 'Pendiente',
          estado_id: 2,
          numero_vc: '',
        },
      ],
    };
  },
  methods: {
    cancelarModal() {
      this.$bvModal.hide('modal-tratamiento');
    },
    confirmarModal() {
      this.$bvModal.hide('modal-tratamiento');
    },
  },
};
</script>
