<template>
  <div>
    <button
      class="btn px-2 mr-1"
      :class="isGuardar ? 'btn-success' : 'btn-light'"
      @click="openModalHospitalClinica(id)"
      disabled
    > H/C</button>
    <b-modal
    :id="'modal-hospital-clinica-'+id"
    size="lg"
    hide-footer>
      <template #modal-header="{ close }">
        <div class="col-sm-12">
          <div class="form-group row">
            <div class="col-sm-9">
              <span><strong>Datos: </strong> Factura Hospital/Clinica</span>
            </div>
            <div class="col-sm-2 text-right">
              <label class="switch switch-success mr-3">
                <input type="checkbox" id="filter"
                  :checked="estado"
                  v-model="estado">
                <span class="slider"></span>
                <span>{{ labelTipo }}</span>
              </label>
            </div>
            <div class="col-sm-1 text-right">
              <span class="font-weight-bold align-middle" @click="close()"
                style="cursor: pointer">X</span>
            </div>
          </div>
        </div>
      </template>
      <div class="modal-body">
        <div class="form-group row mb-1">
          <label for="Medico" class="col-sm-2 col-form-label text-right">
            Medico:
          </label>
          <div class="col-sm-5">
            <multiselect
              v-if="this.estado"
              v-model="medicoFacturaSelected"
              :options="getMedicos"
              placeholder=""
              selectedLabel=""
              label="nombre"
              track-by="id"
              select-label=""
              deselect-label="X"
              @select="selectMedico"
            ></multiselect>
            <input type="text" class="form-control" v-else v-model="medicoFacturaSelected">
          </div>
          <label for="Matricula" class="col-sm-2 col-form-label text-right">
            Matricula:
          </label>
          <div class="col-sm-3">
            <input type="text" class="form-control" v-model="matriculaMedico"
              :disabled="estado">
          </div>
        </div>
        <div class="form-group row mb-1">
          <label for="Especialista" class="col-sm-2 col-form-label text-right">
            Especialista:
          </label>
          <div class="col-sm-5">
            <input type="text" class="form-control" v-model="especialidadMedico"
            :disabled="estado">
          </div>
          <label for="Nit" class="col-sm-2 col-form-label text-right">
            Nit:
          </label>
          <div class="col-sm-3">
            <input type="text" class="form-control" v-model="nitHospitalClinica">
          </div>
        </div>
        <div class="form-group row mb-1">
          <label for="Servicio" class="col-sm-2 col-form-label text-right">
            Servicio:
          </label>
          <div class="col-sm-5">
            <input type="text" class="form-control" disabled v-model="servicio">
          </div>
          <label for="numero_factura" class="col-sm-2 col-form-label text-right">
            N° Factura:
          </label>
          <div class="col-sm-3">
            <input type="text" class="form-control" v-model="numFacturaMedicoClinica">
          </div>
        </div>
        <div class="form-group row mb-1">
          <label for="Quirofano" class="col-sm-9 col-form-label text-right">
            Quirofano:
          </label>
          <div class="col-sm-3">
            <input type="text" class="form-control" v-model="quirofano">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-1 col-form-label text-right">Descripcion: </label>
          <input type="text" class="form-control" v-model="descirpiconMedicoClinica">
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" @click="cerrarModal">Cancelar</button>
        <button class="btn btn-success" type="button" @click="guardar">Guardar</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
  name: 'ModalHospitalClinica',
  components: {
    Multiselect,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    servicio: {
      required: true,
    },
  },
  data() {
    return {
      estado: true,
      medicoFacturaSelected: null,
      matriculaMedico: null,
      especialidadMedico: null,
      nitHospitalClinica: null,
      numFacturaMedicoClinica: null,
      quirofano: null,
      descirpiconMedicoClinica: null,
      isGuardar: false,
    };
  },
  methods: {
    openModalHospitalClinica(index) {
      this.$bvModal.show(`modal-hospital-clinica-${index}`);
    },
    selectMedico(item) {
      if (this.estado) {
        this.matriculaMedico = item.numero_matricula;
        this.especialidadMedico = item.especialidad;
      }
    },
    reset() {
      this.matriculaMedico = null;
      this.especialidadMedico = null;
      this.medicoFacturaSelected = null;
    },
    guardar() {
      this.isGuardar = true;
      const DATA = {
        medicoFacturaSelected: this.medicoFacturaSelected,
        matriculaMedico: this.matriculaMedico,
        especialidadMedico: this.especialidadMedico,
        nitHospitalClinica: this.nitHospitalClinica,
        numFacturaMedicoClinica: this.numFacturaMedicoClinica,
        quirofano: this.quirofano,
        descirpiconMedicoClinica: this.descirpiconMedicoClinica,
      };
      this.$emit('getDataHospitalClinica', DATA);
      this.cerrarModal();
    },
    cerrarModal() {
      this.$bvModal.hide(`modal-hospital-clinica-${this.id}`);
    },
  },
  computed: {
    labelTipo() {
      if (this.estado) {
        return 'BD';
      }
      return 'S/A';
    },
    ...mapGetters('formFactura', [
      'getMedicos',
    ]),
  },
  watch: {
    estado() {
      this.reset();
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
