<template>
  <div class="row">
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="th-custom-color" width="5%">Cantidad</th>
            <th class="th-custom-color text-center" width="20%">Servicio</th>
            <th class="th-custom-color text-center" width="15%"
              v-if="hasPermisionListarConsultaExterna">
              Derivador
            </th>
            <th class="th-custom-color text-center" width="15%">Tratante</th>
            <th class="th-custom-color text-center" width="15%"></th>
            <th class="th-custom-color text-center" width="7%" v-if="isDisableSeguro">{{ labelSeguroDoble }}</th>
            <th class="th-custom-color text-center" width="7%" v-if="isDisableSeguro">{{ labelSeguro }}</th>
            <th class="th-custom-color text-center" width="8%">P/U</th>
            <th class="th-custom-color" width="5%">Total</th>
            <th class="th-custom-color text-center" width="3%"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in listaTabla" :key="item.idRow">
            <td>
              <vue-numeric class="form-control"
                  v-model="item.cantidad"
                  :disabled="isBloqueadoPrecioVenta"
                  v-on:input.native="getCantidad(item, index); calcularTotal(item, index);"
                />
            </td>
            <td class="text-left">({{item.numero}}) {{item.servicio}}</td>
            <td class="text-center" v-if="hasPermisionListarConsultaExterna">
              <div class="row">
                <div class="col-sm-11 pr-1">
                  <multiselect v-model="item.medicoDerivadorSelected"
                    :options="getMedicoDerivador"
                    @input="() => {
                      if(item.medicoDerivadorSelected === null)
                      {
                        item.medico_derivador_id = null;
                      } else {
                        item.medico_derivador_id = item.medicoDerivadorSelected.id_medico
                      }
                    }"
                    placeholder=""
                    label="nombre"
                    track-by="id"
                    select-label=""
                    deselect-label="X"
                    >
                    <span slot="caret" v-if="!(item.medicoDerivadorSelected===null)"></span>
                    <span slot="noResult">Sin Resultados</span>
                    <span slot="noOptions">Lista Vacia</span>
                    <template slot="singleLabel" slot-scope="props">
                      <small>{{props.option.nombre}}</small>
                    </template>
                    <template slot="option" slot-scope="props">
                      <small>{{props.option.nombre}}</small>
                    </template>
                  </multiselect>
                </div>
              </div>
            </td>
            <td class="text-center">
              <div class="row">
                <div class="col-sm-11 pr-1">
                  <multiselect v-model="item.medicoTempSelected"
                    :options="getMedicosTratantes"
                    :loading="item.loading"
                    @input="() => {
                      if(item.medicoTempSelected === null)
                      {
                        item.medico_id = null;
                      } else {
                        item.medico_id = item.medicoTempSelected.id
                      }
                    }"
                    placeholder=""
                    label="nombre"
                    track-by="id"
                    select-label=""
                    deselect-label="X"
                    :disabled="getReservaId ? true : false"
                    @open="getTratanteByService(item.servicio_id, index)">
                    <span slot="caret" v-if="!(item.medicoTempSelected===null)"></span>
                    <span slot="noResult">Sin Resultados</span>
                    <span slot="noOptions">Lista Vacia</span>
                    <template slot="singleLabel" slot-scope="props">
                      <small>{{props.option.nombre}}</small>
                    </template>
                    <template slot="option" slot-scope="props">
                      <small>{{props.option.nombre}}</small>
                    </template>
                  </multiselect>
                </div>
                <div class="col-sm-1 pt-2 px-0" v-if="index === 0 && !hasPermisionFiltroTratante">
                  <svg xmlns="http://www.w3.org/2000/svg"
                    @click="replicarMedicos(item)"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-lightning-fill text-warning accion-rapida-medico"
                    viewBox="0 0 16 16">
                      <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1
                      .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"/>
                  </svg>
                </div>
              </div>
            </td>
            <td class="text-center">
              <div class="form-group row" style="margin: auto;">
                <template v-if="hasPermisionTurnoMedico">
                  <ModalTurno
                    v-if="cierreConfirmado === false"
                    :id="item.idRow"
                    :key="item.idRow"
                    :editMode="getModoEdicion"
                    :medicoSelected="item.medicoTempSelected"
                    @getData="getTurnoData(index, $event)"
                    :turnoFromEdit="item.numero_turno"
                    :isTurnoManualFromEdit="item.is_turno_manual"
                    :fechaTurnoFromEdit="item.fecha_turno"
                    :horaTurnoFromEdit="item.hora_turno"
                    :numeroTicketFromEdit="item.numero_ticket"
                    :tipo_atencion_idFromEdit="item.tipo_atencion_id"
                  />
                  <button v-else
                    class="btn px-2 btn-light mr-2">
                    Turno
                  </button>
                </template>
                <ModalHospitalClinica
                :id="`${item.idRow}-HC`"
                :key="`${item.idRow}-HC`"
                :servicio="item.servicio"
                @getDataHospitalClinica="getDataHospitalClinica"/>
                <TheModalLaboratorio
                  v-if="hasEnabledLaboratory"
                  :id="`${item.idRow}-lab`"
                  :key="`${item.idRow}-lab`"
                  style="display: none;"
                />
              </div>
            </td>
            <td class="text-center" v-if="isDisableSeguro">
              {{
                ((parseFloat(item.seguro) > 0)? item.precio_unitario : 0) | numeral('0,0.00')
              }}
            </td>
            <td class="text-center" v-if="isDisableSeguro">
              <span class="text-success font-weight-bold">
                {{item.seguro | numeral('0,0.00')}}
              </span>
            </td>
            <td class="text-center">
              <span v-if="isBloqueadoPrecioVenta || parseFloat(item.seguro) > 0">
                {{ ((parseFloat(item.seguro) > 0)? 0 : item.precio_unitario) | numeral('0,0.00') }}
              </span>
              <vue-numeric class="form-control" v-else
                  v-model="item.precio_unitario"
                  :disabled="isBloqueadoPrecioVenta"
                  separator=","
                  :precision="2"
                  v-on:input.native="calcularTotal(item, index)"
                />
            </td>
            <td>{{item.total | numeral('0,0.00')}}</td>
            <td class="text-center">
              <i class="nav-icon i-Close-Window font-weight-bold"
                v-if="getReservaId && getModoEdicion === true"
                style="cursor: pointer"
              ></i>
              <i class="nav-icon i-Close-Window text-danger font-weight-bold"
                v-else
                @click="eliminarItem(index)"
                style="cursor: pointer"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import VueNumeric from 'vue-numeric';
import { round } from 'mathjs';
import privilegios from '@/plugins/privilegios/index';
import { mapFields } from 'vuex-map-fields';
import {
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';
import ModalTurno from './ModalTurno.vue';
import ModalHospitalClinica from './ModalHospitalClinica.vue';
import TheModalLaboratorio from './TheModalLaboratorio.vue';

export default {
  name: 'TableServices',
  components: {
    Multiselect,
    VueNumeric,
    ModalTurno,
    ModalHospitalClinica,
    TheModalLaboratorio,
  },
  data() {
    return {
      isDisableSeguro: false,
      isTurnoActive: false,
    };
  },
  mixins: [privilegios],
  created() {
    const funcionalidadId = this.funcionalidadAccions.SEG_LISTAR.funcionalidad_id;
    const funcionalidadAccion = this.funcionalidadAccions.SEG_LISTAR.accion;
    this.isDisableSeguro = this.existePrivilegios(funcionalidadId, funcionalidadAccion);
  },
  methods: {
    actualizarDescripcionItem(e, indice) {
      this.listaTabla[indice].descripcion = e.target.value;
    },
    eliminarItem(index) {
      this.cierreConfirmado = false;
      if (this.getCheckFacturaTercero) {
        this.setClassButton('btn-danger');
        this.resetFormaPagoFacturaTercero();
        this.listaBadges = [];
      }
      if (this.nroCajas > 1) {
        this.setClassButton('btn-danger');
        this.nroCajas = 1;
        this.modalComboSelected2 = null;
        this.modalComboSelected3 = null;
        this.monto2 = 0;
        this.monto3 = 0;
        this.listaBadges = [];
      }
      this.resetAjustesModal();
      this.listaTabla.splice(index, 1);
      this.actualizarTotalSeguro();
      this.actualizarTotalSeguroDoble();
      this.actualizarSubTotal();
      if (this.listaTabla.length === 0) {
        this.setActividadEconomicaId(0);
        this.setDocumentoSectorId(null);
        this.setSiatTipoFacturaId(null);
        this.getServiciosApi();
      }
    },
    replicarMedicos(medicoItem) {
      this.listaTabla.forEach((item, index) => {
        this.listaTabla[index].medicoTempSelected = medicoItem.medicoTempSelected;
        if (medicoItem.medicoTempSelected === null) {
          this.listaTabla[index].medico_id = null;
        } else {
          this.listaTabla[index].medico_id = medicoItem.medicoTempSelected.id;
        }
      });
    },
    getTurnoData(index, data) {
      this.listaTabla[index].fecha_turno = data.fecha_turno;
      this.listaTabla[index].hora_turno = data.hora_turno;
      this.listaTabla[index].numero_ticket = data.numero_ticket;
      this.listaTabla[index].turno_medico_id = data.turno_medico_id;
      this.listaTabla[index].is_turno_manual = data.is_turno_manual;
      this.listaTabla[index].tipo_atencion_id = data.tipo_atencion_id;
    },
    getCantidad(index, data) {
      if (data.cantidad >= 1) {
        this.listaTabla[index].cantidad = data.cantidad;
      }
    },
    ...mapMutations('addServiceModal', [
      'setActividadEconomicaId',
      'setDocumentoSectorId',
      'setSiatTipoFacturaId',
    ]),
    ...mapMutations('formaPagoModal', [
      'setClassButton',
    ]),
    ...mapActions('addServiceModal', [
      'getServiciosApi',
    ]),
    ...mapActions('formaPagoModal', [
      'resetAjustesModal',
    ]),
    ...mapActions('formaPagoFacturaTercero', [
      'resetFormaPagoFacturaTercero',
    ]),
    ...mapActions('detalleFactura', [
      'actualizarSubTotal',
      'actualizarTotalSeguro',
      'actualizarTotalSeguroDoble',
    ]),
    ...mapActions('formFactura', [
      'getTratanteByServicioId',
    ]),
    getDataHospitalClinica(data) {
      console.log('data', data);
    },
    calcularTotal(item, index) {
      const resultado = round(parseFloat(item.cantidad * item.precio_unitario), 2);
      this.listaTabla[index].total = resultado;
      this.actualizarSubTotal();
    },
    async getTratanteByService(servicioId, index) {
      if (this.hasPermisionFiltroTratante) {
        await this.addLoadingListaTabla();
        this.listaTabla[index].loading = true;
        const RESULT_OK = await this.getTratanteByServicioId(servicioId);
        if (RESULT_OK) {
          this.listaTabla[index].loading = false;
        }
      }
    },
    addLoadingListaTabla() {
      this.listaTabla.forEach((element, index) => {
        this.$set(this.listaTabla[index], 'loading', false);
      });
    },
  },
  computed: {
    ...mapFields('facturacion', [
      'hasEnabledLaboratory',
    ]),
    ...mapFields('modalCierreTratamiento', [
      'cierreConfirmado',
    ]),
    ...mapFields('tableAndAddService', ['listaTabla']),
    ...mapFields('formaPagoModal', [
      'nroCajas',
      'modalComboSelected2',
      'modalComboSelected3',
      'monto2',
      'monto3',
    ]),
    ...mapFields('detalleFactura', [
      'listaBadges',
      'seguroDobleSelected',
    ]),
    ...mapFields('main', [
      'funcionalidadAccions',
    ]),
    ...mapGetters('formFactura', [
      'getMedicos',
      'getMedicosTratantes',
      'getCheckFacturaTercero',
      'getMedicoDerivador',
    ]),
    ...mapGetters('facturacion', [
      'getModoEdicion',
    ]),
    ...mapGetters('main', [
      'hasPermisionTurnoMedico',
      'isBloqueadoPrecioVenta',
      'hasPermisionFiltroTratante',
      'hasPermisionListarConsultaExterna',
    ]),
    ...mapGetters('modalReserva', [
      'getReservaId',
    ]),
    labelSeguro() {
      let nombre = 'Seguro';
      if (this.seguroDobleSelected) {
        nombre = 'Seguro 1';
      }
      return nombre;
    },
    labelSeguroDoble() {
      let nombre = 'CoaSeguro';
      if (this.seguroDobleSelected) {
        nombre = 'Seguro 2';
      }
      return nombre;
    },
  },
};
</script>

<style scoped>
.accion-rapida-medico {
  cursor: pointer;
}
.accion-rapida-medico:active {
  color: orange !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
