<template>
  <div>
    <button title="Laboratorio" type="button"
    class="btn px-2 mr-1"
    :class="isGuardar ? 'btn-success' : 'btn-light'"
    @click="openModalLab(id)">Lab.</button>
    <b-modal
    :id="'modal-laboratorio-'+id"
    hide-footer
    title="Laboratorio"
    size="md">
      <div class="modal-boddy">
        <div class="row">
          <div class="col-sm-12 mb-2">
            <div class="form-group row titulo-checkbox mb-2">
              <label class="checkbox checkbox-success col-sm-10 col-form-label text-right">
                <input
                  type="checkbox"
                  id="activar"
                  v-model="inmunohematologia"
                  @click="seleccionarTodo(1)"
                />
                <span>TS 233 - INMUNOHEMATOLOGIA</span>
                <span class="checkmark"></span>
              </label>
              <div class="col-sm-2">
                <i class="i-Arrow-Down-2" style="color:black;
                  font-size: 11px; cursor: pointer;" title="desplegar"
                  @click="desplegarItem(1)">
                  </i>
              </div>
            </div>
            <div class="form-group row subtitulo" v-show="sub_menu1">
              <label class="checkbox checkbox-success col-sm-10 col-form-label">
                <input
                  type="checkbox"
                  id="activar"
                  v-model="hemograma"
                />
                <span>Hemograma Completo</span>
                <span class="checkmark"></span>
              </label>
              <label class="checkbox checkbox-success col-sm-10 col-form-label">
                <input
                  type="checkbox"
                  id="activar"
                  v-model="plaquetas"
                />
                <span>Recuento de Plaquetas</span>
                <span class="checkmark"></span>
              </label>
              <label class="checkbox checkbox-success col-sm-10 col-form-label">
                <input
                  type="checkbox"
                  id="activar"
                  v-model="entrosedimentacion"
                />
                <span>Entrosedimentacion VSG</span>
                <span class="checkmark"></span>
              </label>
              <label class="checkbox checkbox-success col-sm-10 col-form-label">
                <input
                  type="checkbox"
                  id="activar"
                  v-model="reticulocitos"
                />
                <span>Reticulocitos</span>
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <!-- COAGULOGRAMA -->
          <div class="col-sm-12 mb-2">
            <div class="form-group row titulo-checkbox mb-2">
              <label class="checkbox checkbox-success col-sm-10 col-form-label text-right">
                <input
                  type="checkbox"
                  id="activar"
                  v-model="coagulograma"
                  @click="seleccionarTodo(2)"
                />
                <span>TS 23 - COAGULOGRAMA</span>
                <span class="checkmark"></span>
              </label>
              <div class="col-sm-2">
                <i class="i-Arrow-Down-2" style="color:black;
                font-size: 11px; cursor: pointer;" title="desplegar"
                @click="desplegarItem(2)"></i>
              </div>
            </div>
            <div class="form-group row subtitulo" v-show="sub_menu2">
              <label class="checkbox checkbox-success col-sm-10 col-form-label">
                <input
                  type="checkbox"
                  id="activar"
                  v-model="opcion1"
                />
                <span>Opcion 1</span>
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <!-- QUIMICA SANGUINEA -->
          <div class="col-sm-12 mb-2">
            <div class="form-group row titulo-checkbox mr-2">
              <label class="checkbox checkbox-success col-sm-10 col-form-label text-right">
                <input
                  type="checkbox"
                  id="activar"
                />
                <span>TS 233 - QUIMICA SANGUINEA</span>
                <span class="checkmark"></span>
              </label>
              <div class="col-sm-2">
                <i class="i-Arrow-Down-2" style="color:black;
                font-size: 11px; cursor: pointer;" title="desplegar"></i>
              </div>
            </div>
          </div>
          <!-- SEROLOGIA E INMUNOLOGIA -->
          <div class="col-sm-12 mb-2">
            <div class="form-group row titulo-checkbox mr-2">
              <label class="checkbox checkbox-success col-sm-10 col-form-label text-right">
                <input
                  type="checkbox"
                  id="activar"
                />
                <span>TS 27 - SEROLOGIA E INMUNOLOGIA</span>
                <span class="checkmark"></span>
              </label>
              <div class="col-sm-2">
                <i class="i-Arrow-Down-2" style="color:black;
                font-size: 11px; cursor: pointer;" title="desplegar"></i>
              </div>
            </div>
          </div>
          <!-- HORMONAS -->
          <div class="col-sm-12 mb-2">
            <div class="form-group row titulo-checkbox mr-2">
              <label class="checkbox checkbox-success col-sm-10 col-form-label text-right">
                <input
                  type="checkbox"
                  id="activar"
                />
                <span>TS 257 - HORMONAS</span>
                <span class="checkmark"></span>
              </label>
              <div class="col-sm-2">
                <i class="i-Arrow-Down-2" style="color:black;
                font-size: 11px; cursor: pointer;" title="desplegar"></i>
              </div>
            </div>
          </div>
          <!-- MARCADORES CARDICO -->
          <div class="col-sm-12 mb-2">
            <div class="form-group row titulo-checkbox mr-2">
              <label class="checkbox checkbox-success col-sm-10 col-form-label text-right">
                <input
                  type="checkbox"
                  id="activar"
                />
                <span>TS 27 - MARCODORES CARDICO</span>
                <span class="checkmark"></span>
              </label>
              <div class="col-sm-2">
                <i class="i-Arrow-Down-2" style="color:black;
                font-size: 11px; cursor: pointer;" title="desplegar"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button">
          Cancelar
        </button>
        <button class="btn btn-success" type="button"
        @click="cerrarModal(id)">
          Guardar
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'Modal-Laboratorio',
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isGuardar: false,
      sub_menu1: false,
      sub_menu2: false,

      inmunohematologia: false,
      hemograma: false,
      plaquetas: false,
      reticulocitos: false,
      entrosedimentacion: false,

      coagulograma: false,
      opcion1: false,
    };
  },
  methods: {
    openModalLab(index) {
      this.$bvModal.show(`modal-laboratorio-${index}`);
    },
    desplegarItem(index) {
      switch (index) {
        case 1:
          this.sub_menu1 = !this.sub_menu1;
          break;
        case 2:
          this.sub_menu2 = !this.sub_menu2;
          break;
        default:
          break;
      }
    },
    cerrarModal(index) {
      this.isGuardar = true;
      this.$bvModal.hide(`modal-laboratorio-${index}`);
    },
    seleccionarTodo(index) {
      switch (index) {
        case 1:
          this.hemograma = !this.inmunohematologia;
          this.plaquetas = !this.inmunohematologia;
          this.reticulocitos = !this.inmunohematologia;
          this.entrosedimentacion = !this.inmunohematologia;
          break;
        case 2:
          this.opcion1 = !this.coagulograma;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style>
.titulo-checkbox {
  background: #eee;
  padding: 2%;
  margin: auto;
}
.subtitulo {
  margin-left: 15%;
}
</style>
