<template>
  <b-modal id="modal-cierreTratamiento"
    size='lg'
    title="Cierre Tratamiento"
    hide-footer
  >
    <template #modal-header="{ close }">
      <div class="container row">
        <div class="col-sm-6 pl-0">
          <h5 class="modal-title">Cierre Tratamiento N° {{getNumeroItemChecked}}</h5>
        </div>
      </div>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="close()"
      >×</button>
    </template>
    <div class="row mb-3">
      <div class="col-sm-9">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group mb-1">
              <label>Glosa Pre-Cierre:</label>
               <textarea
                  v-text="getGlosaPreCierreItemChecked"
                  :disabled="true"
                  class="form-control"
                  placeholder=""
                  rows="3"
                ></textarea>
            </div>
          </div>
          <div
            v-if="getUserPreCierreItemChecked !== null && getUserPreCierreItemChecked !== ''"
            class="col-sm-12 text-right"
          >
            <div class="form-group">
              <label class="col-form-label py-0">
                User: {{getUserPreCierreItemChecked}}
              </label>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-sm-12">
            <div class="form-group mb-1">
              <label>Glosa Cierre:</label>
               <textarea
                  v-text="getGlosaCierreItemChecked"
                  :disabled="true"
                  class="form-control"
                  placeholder=""
                  rows="3"
                ></textarea>
            </div>
          </div>
          <div
            v-if="getUserCierreItemChecked !== null && getUserCierreItemChecked !== ''"
            class="col-sm-12 text-right"
          >
            <div class="form-group">
              <label class="col-form-label py-0">
                User: {{getUserCierreItemChecked}}
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2 text-right">
            <label class="col-form-label">Servicio:</label>
          </div>
          <div class="col-sm-7 pl-0">
            <multiselect v-model="servicioSelected"
              :options="listaServicios"
              @input="onInputServicios"
              placeholder=""
              label="nombre"
              track-by="id"
              select-label=""
              deselect-label="X"
            >
              <span slot="caret" v-if="!(servicioSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
              <template slot="singleLabel" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
              <template slot="option" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="row">
          <div class="col-sm-10 offset-sm-1">
            <div class="form-group">
              <label>Total:</label>
              <input
                type="text"
                class="form-control"
                :value="getTotalItemChecked | numeral('0,0.00')"
                :disabled="true"
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-10 offset-sm-1">
            <div class="form-group">
              <label>Anticipos:</label>
              <input
                type="text"
                class="form-control"
                :value="getAnticipoItemChecked | numeral('0,0.00')"
                :disabled="true"
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-10 offset-sm-1">
            <div class="form-group">
              <label>Saldo:</label>
              <input
                type="text"
                class="form-control"
                :value="getSaldoItemChecked | numeral('0,0.00')"
                :disabled="true"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-right">
        <button class="btn btn-outline-dark mr-2" @click="cancelarModal()">
          Cancelar
        </button>
        <button class="btn btn-success" @click="confirmarModal()">
          Confirmar
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import Multiselect from 'vue-multiselect';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { util } from '@/plugins/util';

export default {
  name: 'ModalCierreTratamiento',
  components: {
    Multiselect,
  },
  data() {
    return {
      servicioSelected: null,
    };
  },
  created() {
    this.getServicios();
  },
  methods: {
    ...mapActions('modalCierreTratamiento', [
      'getServicios',
      'getNumeroFactura',
    ]),
    ...mapActions('detalleFactura', [
      'actualizarSubTotal',
      'actualizarTotalSeguro',
      'actualizarTotalSeguroDoble',
    ]),
    ...mapMutations('addServiceModal', [
      'setActividadEconomicaId',
      'incrementarIdRow',
    ]),
    ...mapMutations('tableAndAddService', [
      'addItemlistaTabla',
    ]),
    cancelarModal() {
      this.cierreConfirmado = false;
      this.$bvModal.hide('modal-cierreTratamiento');
    },
    confirmarModal() {
      if (this.servicioSelected === null) {
        util.showNotify('Elije un Servicio', 'warn');
        return;
      }
      this.cierreConfirmado = true;
      const descripcion = 'viene de tratamiento';
      let siatActividadEconomicaId = this.servicioSelected.siat_actividad_economica_id;
      let siatDescripcionService = this.servicioSelected.siat_descripcion_service;
      let siatDescripcionUnidadMedida = this.servicioSelected.siat_descripcion_unidad_medida;
      let siatTipoProductoServiceId = this.servicioSelected.siat_tipo_producto_service_id;
      let siatUnidadMedidaId = this.servicioSelected.siat_unidad_medida_id;
      const medico = this.getMedicoSelected;
      if (medico) {
        if (medico.configuracion_factura_tercero === 0) {
          siatActividadEconomicaId = medico.siat_actividad_economica_id;
          siatDescripcionService = medico.siat_nombre_producto_servicio;
          siatDescripcionUnidadMedida = medico.siat_nombre_unidad_medida;
          siatTipoProductoServiceId = medico.siat_producto_servicio_id;
          siatUnidadMedidaId = medico.siat_unidad_medida_id;
        }
      }
      const ITEM_SERVICIO = {
        tratamiento_id: this.getIdItemChecked,
        numero: this.getNumeroItemChecked,
        servicio_id: this.servicioSelected.id,
        descripcion,
        medicoTempSelected: null,
        cantidad: 1,
        precio_unitario: this.getTotalItemChecked,
        total: this.getTotalItemChecked,
        servicio: this.servicioSelected.nombre,
        seguro: '0.00',
        fecha_turno: null,
        hora_turno: null,
        numero_ticket: null,
        numero_turno: null,
        turno_medico_id: null,
        is_turno_manual: false,
        siat_actividad_economica_id: siatActividadEconomicaId,
        siat_descripcion_service: siatDescripcionService,
        siat_descripcion_unidad_medida: siatDescripcionUnidadMedida,
        siat_tipo_producto_service_id: siatTipoProductoServiceId,
        siat_unidad_medida_id: siatUnidadMedidaId,
        idRow: this.idRow,
      };
      this.incrementarIdRow();
      this.addItemlistaTabla(ITEM_SERVICIO);
      this.setActividadEconomicaId(this.servicioSelected.actividad_economica_id);
      this.actualizarSubTotal();
      this.actualizarTotalSeguro();
      this.actualizarTotalSeguroDoble();
      this.$bvModal.hide('modal-cierreTratamiento');
    },
    onInputServicios() {
      if (this.servicioSelected === null) {
        return;
      }
      this.getNumeroFactura(this.servicioSelected.id);
    },
  },
  computed: {
    ...mapFields('modalCierreTratamiento', [
      'cierreConfirmado',
      'listaServicios',
    ]),
    ...mapGetters('formFactura', [
      'getMedicoSelected',
    ]),
    ...mapFields('addServiceModal', [
      'idRow',
    ]),
    ...mapGetters('modalTratamientoEnCurso', [
      'getIdItemChecked',
      'getNumeroItemChecked',
      'getTotalItemChecked',
      'getAnticipoItemChecked',
      'getSaldoItemChecked',
      'getGlosaPreCierreItemChecked',
      'getGlosaCierreItemChecked',
      'getUserPreCierreItemChecked',
      'getUserCierreItemChecked',
    ]),
  },
};
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
