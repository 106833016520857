<template>
  <div>
    <!-- solo cuando viene del modal reserva -ventas -->
    <button
      v-if="reservaId && !getModoEdicion"
      class="btn px-2 mr-2 btn-success"
      disabled
    >Turno
    </button>
    <!-- fin solo cuando viene del modal reserva -ventas  -->
    <button
    v-if="hasMedicTurnos && !isReconsulta"
      @click="onClickTurno(id)"
      class="btn px-2 mr-2"
      :class="getButtonClass"
    >Turno
    </button>
    <b-modal
      :id="'modal-turno-'+id"
      title="Programar Atención"
      hide-footer
    >
      <template #modal-header="{ close }">
        <div class="container row">
          <div class="col-sm-6 pl-0">
            <h5
              class="modal-title"
              :class="getAutoClassTitleCard"
            >Médico: {{titleCard}}</h5>
          </div>
          <div class="col-sm-6 pl-0">
            <button v-if="stateTurno1"
              @click="selectTurno1"
              class="btn mr-3"
              :class="turno === 1? 'btn-success' : 'btn-secondary'"
            >Turno 1</button>
            <button v-if="stateTurno2"
              @click="selectTurno2"
              class="btn"
              :class="turno === 2? 'btn-success' : 'btn-secondary'"
            >Turno 2</button>
          </div>
        </div>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="close()"
        >×</button>
      </template>
      <div class="row mb-3">
        <div class="col-sm-3 text-right">
          <label class="col-form-label">Fecha:</label>
        </div>
        <div class="col-sm-3 px-0">
          <date-picker
            class="col-sm-12 px-0"
            v-model="inputFecha"
            @input="onInputFecha"
            :disabled-date="disabledBeforeToday"
            lang="es"
            format="DD/MM/YYYY"
            type="date"
            value-type="date"
          ></date-picker>
        </div>
        <div class="col-sm-3 px-1" v-if="tipoTurnoMedicoId === 1">
          <input
            :value="horariosDisponibles"
            @click="isSeleccionManual=!isSeleccionManual"
            type="text"
            class="form-control text-center"
            style="cursor:pointer;"
            readonly="true"
          >
        </div>
        <div class="col-sm-3 px-1" v-if="isDisabledEnfermeria">
          <button class="btn btn-block bg-pastelYellow">Enfermeria</button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3 text-right" v-if="tipoTurnoMedicoId !== 3">
          <label class="col-form-label">Horario:</label>
        </div>
        <div
          v-if="isSeleccionManual || tipoTurnoMedicoId === 2"
          class="col-sm-3 pl-0 pr-4"
        >
          <input
            v-model="horarioManualValue"
            @input="sendData"
            type="time"
            class="form-control"
          >
        </div>
        <div
          v-if="!isSeleccionManual && tipoTurnoMedicoId === 1"
          class="col-sm-4 pl-0"
        >
          <multiselect
            v-model="horarioSelected"
            :options="listaHorarios"
            @input="sendData"
            placeholder=""
            selectedLabel=""
            label="hora"
            track-by="ticket"
            select-label=""
            deselect-label="X"
          >
            <span slot="caret" v-if="!(horarioSelected===null)"></span>
            <span slot="noResult">Sin Resultados</span>
            <span slot="noOptions">No trabaja</span>
            <template slot="singleLabel" slot-scope="props">
              <small>{{props.option.ticket}} - {{props.option.hora}}</small>
            </template>
            <template slot="option" slot-scope="props">
              <small v-if="formatOptionLabel(props.option.hora)" class="yellow-border"> {{ props.option.ticket }} - {{ props.option.hora }}</small>
              <small v-else>{{ props.option.ticket }} - {{ props.option.hora }}</small>
            </template>
          </multiselect>
        </div>
        <div class="offset-sm-2 col-sm-4 pr-1" v-if="tipoTurnoMedicoId === 3">
        <button
          :class="{ 'btn-success': medicinaGeneralSelected, 'btn-secondary': !medicinaGeneralSelected }"
          class="btn btn-block"
          @click="toggleMedicinaGeneral">Medicina General</button>
      </div>
      <div class="col-sm-4 pl-1" v-if="tipoTurnoMedicoId === 3">
        <button
          :class="{ 'btn-success': emergenciaSelected, 'btn-secondary': !emergenciaSelected }"
          class="btn btn-block"
          @click="toggleEmergencia">Emergencia</button>
      </div>
        <div class="col-sm-4 pt-2" v-if="tipoTurnoMedicoId === 2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-telephone"
            viewBox="0 0 16 16"
          >
            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45
             1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211
             1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678
             0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0
             1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745
             0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0
             .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1
             1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846
             1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0
             1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
          </svg>
          <a
            class="text-dark pl-2"
            :href="'tel:' + getTelefonoMedico"
          >
            Cel.: {{getTelefonoMedico}}
          </a>
        </div>
      </div>
      <div v-if="isReconsulta" class="row mt-3">
        <div class="col-sm-12 text-right">
          <button
            class="btn btn-light-gray mr-2"
            @click="hideModal"

          >
            Cancelar
          </button>
          <button
            class="btn btn-success"
            @click="guardarCambios"
            :disabled="disableSaveButton"
          >
            Guardar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import privilegios from '@/plugins/privilegios/index';
import { mapFields } from 'vuex-map-fields';
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import moment from 'moment';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { mapGetters } from 'vuex';

export default {
  name: 'ModalTurno',
  components: {
    Multiselect,
    DatePicker,
  },
  props: {
    /**
     * id del modal
     */
    id: {
      type: [Number, String],
      required: true,
    },
    /**
     * indica si la interfaz ventas se encuentra en modo editar
     */
    editMode: {
      type: Boolean,
      required: true,
    },
    turnoFromEdit: {
      type: [Number, String],
      required: false,
      default: null,
    },
    isTurnoManualFromEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    fechaTurnoFromEdit: {
      type: String,
      required: false,
      default: null,
    },
    horaTurnoFromEdit: {
      type: String,
      required: false,
      default: null,
    },
    numeroTicketFromEdit: {
      type: [Number, String],
      required: false,
      default: null,
    },
    tipo_atencion_idFromEdit: {
      type: [Number, String],
      default: null,
    },
    /**
     * medico correspondiente, puede ser null.
     */
    medicoSelected: {
      type: Object,
      required: false,
      default: null,
    },

    itemSeleccionado: {
      type: Object,
    },
    isReconsulta: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    async medicoSelected(newVal) {
      this.inputFecha = new Date();
      this.horarioSelected = null;
      /* this.horarioManualValue = ''; */
      this.isSeleccionManual = false;
      this.listaHorarios = [];
      this.turno = 1;
      this.turnoId = null;
      this.sendData();
      if (newVal !== null) {
        await this.getTurnoMedicoStatus();
        this.getDataModal();
      } else {
        this.btnDisabled = this.horaTurnoFromEdit === null;
        this.hasMedicTurnos = false;
      }
    },
    medicinaGeneralSelected(newValue, oldValue) {
      if (newValue && oldValue !== newValue) {
        this.emergenciaSelected = false; // Desseleccionar emergencia si se selecciona medicina general
        this.sendData();
      }
    },
    emergenciaSelected(newValue, oldValue) {
      if (newValue && oldValue !== newValue) {
        this.medicinaGeneralSelected = false; // Desseleccionar medicina general si se selecciona emergencia
        this.sendData();
      }
    },
  },
  data() {
    return {
      inputFecha: null,
      horarioSelected: null,
      listaHorarios: [],
      turno: 1,
      turnoId: null,
      stateTurno1: false,
      stateTurno2: false,
      btnDisabled: true,
      isDisabledEnfermeria: false,
      titleCard: 'Programar Atención',
      tipoTurnoMedicoId: 0,
      isSeleccionManual: false,
      horarioManualValue: '',
      hasMedicTurnos: false,
      medicinaGeneralSelected: false,
      emergenciaSelected: false,
      tipo_atencion_id: null,
      disableSaveButton: false,
    };
  },
  mixins: [privilegios],
  async created() {
    if (this.editMode) {
      if (this.tipo_atencion_idFromEdit !== null) {
        this.tipo_atencion_id = this.tipo_atencion_idFromEdit;
        if (this.tipo_atencion_idFromEdit === 1) {
          this.medicinaGeneralSelected = true;
          this.emergenciaSelected = false;
          this.tipo_atencion_id = 1;
        }
        if (this.tipo_atencion_idFromEdit === 2) {
          this.emergenciaSelected = true;
          this.medicinaGeneralSelected = false;
          this.tipo_atencion_id = 2;
        }
      }
      if (this.turnoFromEdit !== null) {
        this.turno = this.turnoFromEdit;
        this.hasMedicTurnos = true;
      }
      this.isSeleccionManual = this.isTurnoManualFromEdit;
      if (this.fechaTurnoFromEdit !== null) {
        this.inputFecha = new Date(moment(this.fechaTurnoFromEdit));
      } else {
        this.inputFecha = null;
      }
      if (this.numeroTicketFromEdit !== null && this.horaTurnoFromEdit !== null) {
        this.horarioSelected = {
          ticket: this.numeroTicketFromEdit,
          hora: this.formatTime(this.horaTurnoFromEdit),
        };
      }
      if (this.medicoSelected !== null) {
        await this.getTurnoMedicoStatus();
        await this.getDataModal();
      }
      if (this.isSeleccionManual === 2 || this.tipoTurnoMedicoId === 2) {
        this.horarioManualValue = this.horaTurnoFromEdit;
        this.isSeleccionManual = true;
      }
    }
    if (this.funcionalidadAccions.ENF_LISTAR) {
      const funcionalidadId = this.funcionalidadAccions.ENF_LISTAR.funcionalidad_id;
      const funcionalidadAccion = this.funcionalidadAccions.ENF_LISTAR.accion;
      this.isDisabledEnfermeria = await this.existePrivilegios(funcionalidadId, funcionalidadAccion);
    }
  },
  methods: {
    async guardarCambios() {
      if (this.tipoTurnoMedicoId !== 2) {
        if (this.isSeleccionManual === false && this.horarioSelected === null) {
          util.showNotify('Seleccione un horario', 'warn');
          return;
        }
        if (this.isSeleccionManual && this.horarioManualValue === '') {
          util.showNotify('Escriba la hora', 'warn');
          return;
        }
      }
      let numeroTicket = null;
      let horaTurno = null;
      if (this.tipoTurnoMedicoId === 1) {
        if (this.isSeleccionManual) {
          numeroTicket = null;
          horaTurno = this.horarioManualValue;
        } else {
          horaTurno = this.horarioSelected?.hora ?? null;
          numeroTicket = this.horarioSelected?.ticket ?? null;
        }
      }
      if (this.tipoTurnoMedicoId === 2) {
        horaTurno = this.horarioManualValue;
      }
      const REQUESTRECONSULTA = {
        numero_ticket: numeroTicket,
        turno_medico_id: this.turnoId,
        fecha_turno: moment(this.inputFecha).format('YYYY-MM-DD'),
        hora_turno: horaTurno,
        is_turno_manual: this.isSeleccionManual,
        venta_detalle_turno_id: this.itemSeleccionado.venta_detalle_turno_id,
      };

      this.disableSaveButton = true;
      try {
        const RESPONSE = await axios.post(`clinic/invoicing/reconsulta/${this.itemSeleccionado.consulta_id}`, REQUESTRECONSULTA);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.disableSaveButton = false;
        this.$emit('onUpdateTurno', true);
        this.hideModal();
      }
    },
    hideModal() {
      this.$bvModal.hide(`modal-turno-${'reconsulta'}`);
    },
    checkAndSendData() {
      if (this.isSeleccionManual) {
        this.sendData();
      }
    },
    setHorarioActual() {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      this.horarioManualValue = `${hours}:${minutes}`;
      this.sendData();
    },
    onClickTurno(index) {
      if (this.btnDisabled === false) {
        this.$bvModal.show(`modal-turno-${index}`);
      }
    },
    async getTurnoMedicoStatus() {
      try {
        const REQUEST = {
          medico_id: this.medicoSelected.id,
        };
        const RESPONSE = await axios.get('/clinic/invoicing/turno/configuracion', {
          params: REQUEST,
        });
        const TURNOS_MEDICO = RESPONSE.data.data.turno_medico;
        if (TURNOS_MEDICO.length > 0) {
          if (!this.isReconsulta) {
            this.hasMedicTurnos = true;
          }
          const T1 = TURNOS_MEDICO.find((el) => el.numero_turno === 1) ?? null;
          const T2 = TURNOS_MEDICO.find((el) => el.numero_turno === 2) ?? null;
          if (T1 !== null) {
            this.stateTurno1 = true;
            this.btnDisabled = false;
          } else {
            this.stateTurno1 = false;
          }
          if (T2 !== null) {
            this.stateTurno2 = true;
            this.btnDisabled = false;
          } else {
            this.stateTurno2 = false;
          }
          if (T1 !== null && T2 === null) {
            this.turno = 1;
          }
          if (T1 === null && T2 !== null) {
            this.turno = 2;
          }
        } else {
          this.hasMedicTurnos = false;
          this.btnDisabled = true;
          this.stateTurno1 = false;
          this.stateTurno2 = false;
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    formatTime(input) {
      if (input.includes(' - ')) {
        const parts = input.split(' - ');
        const timePart = parts[0];
        const formattedTime = moment(timePart, 'HH:mm:ss').format('HH:mm');
        if (parts.length > 1) {
          return `${formattedTime} - ${parts.slice(1).join(' - ')}`;
        }
        return formattedTime;
      }
      return moment(input, 'HH:mm:ss').format('HH:mm');
    },
    formatOptionLabel(option) {
      if (option.includes(' - ')) {
        const parts = option.split(' - ');
        const timePart = parts[0];
        if (moment(timePart, 'HH:mm:ss').format('HH:mm') === this.horarioSelected?.hora) {
          return true;
        }
      } else if (moment(option, 'HH:mm:ss').format('HH:mm') === this.horarioSelected?.hora) {
        return true;
      }
      return false;
    },
    cargarListaHorarios(lista) {
      const FECHA_SELECCIONADA = moment(this.inputFecha).format('YYYY-MM-DD');
      const FECHA_ACTUAL = moment(new Date()).format('YYYY-MM-DD');
      const HORA_ACTUAL = moment(new Date()).format('HH:mm');
      const TEMP_LIST = [];
      lista.forEach((el) => {
        let estado = false;
        let { hora } = el;
        if (el.hora === 'NO Disponible') {
          estado = true;
        } else if (FECHA_SELECCIONADA < FECHA_ACTUAL) {
          estado = true;
        } else if (HORA_ACTUAL > moment(el.hora, 'HH:mm:ss').format('HH:mm') && FECHA_SELECCIONADA === FECHA_ACTUAL) {
          estado = true;
        } else if (this.horarioSelected != null) {
          if (moment(el.hora, 'HH:mm:ss').format('HH:mm') === this.horarioSelected?.hora) {
            estado = true;
          }
        }
        if (this.numeroTicketFromEdit !== null && this.horaTurnoFromEdit !== null) {
          if (el.ticket === this.numeroTicketFromEdit) {
            hora = this.formatTime(this.horaTurnoFromEdit);
          }
        }
        if (hora) {
          hora = this.formatTime(hora);
        } else {
          hora = '';
        }
        if (hora.includes(' - ')) {
          estado = true;
        }
        const ITEM = {
          $isDisabled: estado,
          ticket: el.ticket,
          hora,
        };
        TEMP_LIST.push(ITEM);
      });
      this.listaHorarios = TEMP_LIST;
    },
    async getDataModal() {
      if (this.medicoSelected === null || this.inputFecha === null) {
        return;
      }
      try {
        const REQUEST = {
          medico_id: this.medicoSelected.id,
          fecha: moment(this.inputFecha).format('YYYY-MM-DD'),
          turno: this.turno,
        };
        const RESPONSE = await axios.get('/clinic/invoicing/turno/', {
          params: REQUEST,
        });
        this.turnoId = RESPONSE.data.data.turno_medico?.id ?? null;
        const TURNOS = RESPONSE.data.data.turno_medico?.turnos ?? [];
        this.titleCard = RESPONSE.data.data.tipo_turno?.tipo_turno ?? 'Programar Atención';
        this.tipoTurnoMedicoId = RESPONSE.data.data.tipo_turno?.tipo_turno_medico_id ?? 0;
        this.cargarListaHorarios(TURNOS);
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
      }
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },
    async onInputFecha() {
      this.horarioSelected = null;
      /* this.horarioManualValue = ''; */
      this.isSeleccionManual = false;
      this.listaHorarios = [];
      await this.getDataModal();
      this.sendData();
    },
    async selectTurno1() {
      this.turno = 1;
      this.horarioSelected = null;
      await this.getDataModal();
      this.sendData();
    },
    async selectTurno2() {
      this.turno = 2;
      this.horarioSelected = null;
      await this.getDataModal();
      this.sendData();
    },
    toggleMedicinaGeneral() {
      if (this.tipoTurnoMedicoId === 3) {
        this.medicinaGeneralSelected = true;
        this.emergenciaSelected = false;
        this.tipo_atencion_id = 1;
        this.sendData();
      }
    },
    toggleEmergencia() {
      if (this.tipoTurnoMedicoId === 3) {
        this.emergenciaSelected = true;
        this.medicinaGeneralSelected = false;
        this.tipo_atencion_id = 2;
        this.sendData();
      }
    },
    sendData() {
      let fechaTurno = null;
      let horaTurno = null;
      let numeroTicket = null;
      if (this.inputFecha !== null) {
        fechaTurno = moment(this.inputFecha).format('YYYY-MM-DD');
      }
      if (this.tipoTurnoMedicoId === 1) {
        if (this.horarioSelected === null || this.isSeleccionManual) {
          horaTurno = this.horarioManualValue;
        } else {
          horaTurno = this.horarioSelected?.hora ?? null;
          numeroTicket = this.horarioSelected?.ticket ?? null;
        }
      }
      if (this.tipoTurnoMedicoId === 2) {
        horaTurno = this.horarioManualValue;
      }
      const DATA = {
        fecha_turno: fechaTurno,
        hora_turno: horaTurno === '' ? null : horaTurno,
        numero_ticket: numeroTicket,
        turno_medico_id: this.turnoId,
        is_turno_manual: this.isSeleccionManual,
        medicina_general_selected: this.medicinaGeneralSelected,
        emergencia_selected: this.emergenciaSelected,
        tipo_atencion_id: this.tipo_atencion_id,
      };
      this.btnDisabled = horaTurno === null;
      this.$emit('getData', DATA);
    },
  },
  computed: {
    horariosDisponibles() {
      if (this.isSeleccionManual) {
        this.setHorarioActual();
        return 'Abierto';
      }
      const LISTA_DISPONIBLES = [];
      this.listaHorarios.forEach((el) => {
        if (el.$isDisabled === false) {
          LISTA_DISPONIBLES.push(el);
        }
      });
      return `Disponible: ${LISTA_DISPONIBLES.length}`;
    },
    getButtonClass() {
      /* if (this.btnDisabled) {
        return 'btn-light';
      }
      if (this.tipoTurnoMedicoId === 3 && this.inputFecha !== null) {
        return 'btn-success';
      }
      return (
        this.inputFecha !== null
        && (this.horarioSelected !== null || this.horarioManualValue !== '')
      ) ? 'btn-success' : 'btn-light'; */
      if (this.medicinaGeneralSelected || this.emergenciaSelected) {
        return 'btn-success';
      }
      if (this.isSeleccionManual) {
        return 'btn-success';
      }
      if (this.tipoTurnoMedicoId === 3 && (this.medicinaGeneralSelected || this.emergenciaSelected)) {
        return 'btn-success';
      }
      return (
        this.inputFecha !== null
        && (this.horarioSelected !== null)
      ) ? 'btn-success' : 'btn-light';
    },
    getAutoClassTitleCard() {
      if (this.tipoTurnoMedicoId === 1) {
        return 'text-success';
      }
      if (this.tipoTurnoMedicoId === 2) {
        return 'text-danger';
      }
      if (this.tipoTurnoMedicoId === 3) {
        return 'text-warning';
      }
      return '';
    },
    getTelefonoMedico() {
      if (this.medicoSelected !== null) {
        return this.medicoSelected.telefono;
      }
      return '';
    },
    ...mapFields('main', [
      'funcionalidadAccions',
    ]),
    ...mapFields('modalReserva', [
      'reservaId',
    ]),
    ...mapGetters('facturacion', [
      'getModoEdicion',
    ]),
  },
};
</script>

<style scoped>
.bg-pastelYellow {
  background: #fff69c;
}
.yellow-border {
  border: 3px solid yellow;
  padding: 11px;
  margin: -10px -10px -5px -10px;
  display: block;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
